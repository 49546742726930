import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit"
import { isBrowser } from "../../components/blog/utils/helpers"
import { TimeFrameListKeys } from "../../constants"
import { ConversorDirection } from "../../feature/token/token_fiat/reducer/types"
import { createGAEvent } from "../../utils/general"
import { tokensEvents } from "../../service/tracking"

const getConversorDirection = () => {
  if (isBrowser) {
    const direction = localStorage.getItem("conversorDirection")
    if (direction) return direction as ConversorDirection
  }
  return "token_fiat"
}

const getUserFirstVisit = () => {
  if (!isBrowser) return false
  let hasView = localStorage.getItem("survey")
  if (!hasView) return true
  return false
}

const initialState: {
  chartType: "line" | "candlestick"
  timeFrameSelected: TimeFrameListKeys
  userFirstVisit: boolean
  conversorDirection: ConversorDirection
} = {
  chartType: "line",
  timeFrameSelected: "month",
  conversorDirection: getConversorDirection(),
  userFirstVisit: getUserFirstVisit(),
}

const isSwitchingTimeFrame = (action: AnyAction) => {
  return action.type.endsWith("/changeTimeFrameSelected")
}

const hasChangedConversorDirection = (action: AnyAction) => {
  return action.type.endsWith("/changeConversorDirection")
}
const isFirstVisit = (action: AnyAction) => {
  return action.type.endsWith("/changeUserFirstVisit")
}

const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    changeChartType(state) {
      state.chartType =
        state.chartType === "candlestick" ? "line" : "candlestick"
    },
    changeTimeFrameSelected(state, action: PayloadAction<TimeFrameListKeys>) {
      if (action.payload !== state.timeFrameSelected)
        state.timeFrameSelected = action.payload
    },
    setTimeFrameToOneDay(state) {
      state.timeFrameSelected = "day"
    },
    changeConversorDirection(state, action: PayloadAction<ConversorDirection>) {
      state.conversorDirection = action.payload
    },
    changeUserFirstVisit(state, _: PayloadAction<string | null>) {
      state.userFirstVisit = false
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isSwitchingTimeFrame,
        (_, action: PayloadAction<TimeFrameListKeys>) => {
          createGAEvent(tokensEvents.change_chart_timeframe, action.payload)()
        }
      )
      .addMatcher(
        hasChangedConversorDirection,
        (_, action: PayloadAction<ConversorDirection>) => {
          if (isBrowser) {
            localStorage.setItem("conversorDirection", action.payload)
          }
        }
      )
      .addMatcher(isFirstVisit, (_, action: PayloadAction<string | null>) => {
        const { payload } = action
        localStorage?.setItem(payload || "userFirstReferralVisit", "false")
      })
  },
})

export const {
  changeChartType,
  changeTimeFrameSelected,
  setTimeFrameToOneDay,
  changeConversorDirection,
  changeUserFirstVisit,
} = slice.actions

export default slice.reducer
