import React from "react"
import { Hero } from "../components/home/Hero"
import { Box } from "@youngagency/young-ui"
import "../components/home/global.css"
import { PartnerSection } from "../components/home/PartnerSection"
import { TrustedBySection } from "../components/home/TrustedBySection"
import { TreasurySection } from "../components/home/TreasurySection"
import { PaymentsSection } from "../components/home/PaymentsSection"
import { CoursesSection } from "../components/home/CoursesSection"
import { HistorySection } from "../components/home/HistorySection"
import { CertificatesSection } from "../components/home/CertificatesSection"
import { FaqsSection } from "../components/home/FaqsSection"
import { AdvocacySection } from "../components/home/AdvocacySection"
export const B2BHomePage = () => {
  // TO DO review tracking & links
  return (
    <Box background="black">
      <Box pt="16px">
        <Hero />
      </Box>
      <Box mt={["56px", "96px"]}>
        <TrustedBySection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <TreasurySection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <PaymentsSection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <CoursesSection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <AdvocacySection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <HistorySection />
      </Box>
      <Box background="white" width="full">
        <Box
          mt={["8px", "16px"]}
          borderRadius={"0px 0px 32px 32px"}
          background="black"
          width="full"
          height={["72px", "48px"]}
          style={{
            transform: "translateY(-1px)",
          }}
        />
        <Box mt={["48px", "96px"]}>
          <CertificatesSection />
        </Box>
        <Box mt={["48px", "96px"]}>
          <PartnerSection />
        </Box>
        <Box mt={["48px", "96px"]} pb={["40px", "72px"]}>
          <FaqsSection />
        </Box>
      </Box>
    </Box>
  )
}
