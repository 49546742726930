module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.12.5_i18next@20.6.1_react-i18next@11.18.6_react@18.2.0/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["it","en","fr"],"defaultLanguage":"it","fallbackLanguage":"it","siteUrl":"https://youngplatform.com/","redirect":false,"i18nextOptions":{"nsSeparator":false,"redirect":false,"generateDefaultLanguagePage":true,"fallbackLng":"it","wait":true,"detection":{"order":["path","localStorage","cookie","navigator"]}},"pages":[{"matchPath":"/:lang?/jobs/:position","getLanguageFromPath":true},{"matchPath":"/:lang?/faq/:faq","getLanguageFromPath":true},{"matchPath":"/:lang?/exchange/","getLanguageFromPath":true},{"matchPath":"/:lang?/exchange/listed/:page?","getLanguageFromPath":true},{"matchPath":"/:lang?/exchange/:coin","getLanguageFromPath":true},{"matchPath":"/:lang?/glossary/:word","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/tag/:tag/:page?","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:category/:post?","getLanguageFromPath":true},{"matchPath":"/:lang?/crypto-attack","getLanguageFromPath":true},{"matchPath":"/:lang?/ooh","getLanguageFromPath":true},{"matchPath":"/:lang?/crypto-attack/:series?","getLanguageFromPath":true},{"matchPath":"/:lang?/legal/:doc?","getLanguageFromPath":true},{"matchPath":"/:lang?/token-yng/","getLanguageFromPath":true},{"matchPath":"/:lang?/token-yng/:kind","getLanguageFromPath":true},{"matchPath":"/:lang?/cda-and-founders/","getLanguageFromPath":true},{"matchPath":"/:lang?/business/:page","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.0_gatsby@5.12.5_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Young Platform","short_name":"Young Platform","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5ba7af8394df032e575d720f1e8cdbc0"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.12.0_babel-plugin-styled-components@2.1.4_gatsby@5.12.5_rea_7uqqeuytjmqkq2a5ft7pebqhpe/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.12.5/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.12.0_gatsby-plugin-image@3.12.0_gatsby-plugin-sharp@5.12.0_gatsby-t_7qz2tok6gn63gvx472qsdvmuva/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blogapi.youngplatform.com/graphql.","schema":{"perPage":10,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":60000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"type":{"MenuItem":{"exclude":true},"Menu":{"exclude":true},"GeneralSetting":{"exclude":true},"Page":{"exclude":true},"Post":{"limit":false},"WpToken":{"limit":false},"WpGlossary":{"limit":false},"WpFaq":{"limit":false},"LegalDocs":{"limit":false},"MediaItem":{"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"debug":{"graphql":{"showQueryVarsOnError":true,"printIntrospectionDiff":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.0_gatsby@5.12.5_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MCGBHGH","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.5_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
