import {
  Box,
  styled,
  css,
  Icon,
  HStack,
  Skeleton,
  Heading,
} from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  BottomBanner,
  BtnContainer,
  Container,
  ResponsiveButton,
  ResponsiveText,
} from "../../components/layout"
import { BreadCrumb, LandingLayout } from "../../feature/navigation/components"
import { AcademyRelatedArticle } from "../../feature/posts/components"
import {
  AlternateHandler,
  MetaDecorator,
  SeoLink,
  WebPageSchema,
} from "../../feature/seo"
import {
  ActionSection,
  LegalFooter,
  PriceSection,
  ReletedLabelTokens,
  TokenContentContainer,
} from "../../feature/token/components"
import FrontMatter from "../../feature/token/components/FrontMatter"
import { useFrontMatter } from "../../feature/token/hooks/useFrontMatter"
import BlogRelatedArticle from "../../feature/token/unlisted/components/BlogRelatedArticle"
import { TranslationProvider } from "../../feature/translations/context"
import { navigate } from "@reach/router"
import {
  BODY_TOKEN_PAGES_DYNAMIC_LINK,
  HEADER_TOKEN_PAGES_DYNAMIC_LINK,
} from "../../feature/token/constants"
import { useGetAllCurrenciesQuery } from "../../redux/services/currency/api"

const StyledStack = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: ["flex-start", "center"],
    "& > *:not(:first-child)": {
      mt: ["16px", "0px"],
      ml: ["0px", "24px"],
    },
  })
)

const FlexWrapper = styled(Box)`
  display: block;
  .front-matter {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    .content-box {
      max-width: 756px;
    }
    & > *:not(:last-of-type) {
      margin-right: 20px;
    }

    .front-matter {
      display: block;
    }
  }
`

const Unlisted = ({ data: { wpToken } }: PageProps<any>) => {
  const { t, language } = useI18next()
  const frontMatter = useFrontMatter()

  const tokenSymbol = wpToken.customFields?.ticker &&
    wpToken.customFields.ticker?.toUpperCase() !== "LUNA"
    ? wpToken.customFields.ticker?.toUpperCase()
    : wpToken.customFields.key

  const { data, isLoading } = useGetAllCurrenciesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      isLoading,
      data: data?.currencies?.[tokenSymbol.toUpperCase()],
    }),
  })

  const isTokenPro = React.useMemo(() => wpToken.customFields.ispro, [])
  const isTokenUnlisted = React.useMemo(
    () => wpToken.customFields.isunlisted,
    []
  )

  const supportLink = React.useMemo(() => {
    switch (language) {
      case "en":
        return "https://support.youngplatform.com/hc/en-us/articles/6606967047186-Why-do-I-see-cryptocurrencies-that-are-not-supported-by-Young-Platform-"
      case "fr":
        return "https://support.youngplatform.com/hc/fr/articles/6606967047186-Pourquoi-est-ce-que-je-vois-des-cryptomonnaies-qui-ne-sont-pas-prises-en-charge-par-Young-Platform-"
      default:
        return "https://support.youngplatform.com/hc/it/articles/6606967047186-Perch%C3%A9-vedo-criptovalute-che-non-sono-supportate-da-Young-Platform-"
    }
  }, [language])

  const handleNavigate = React.useCallback(() => {
    navigate(supportLink)
  }, [supportLink])

  const image = React.useMemo(
    () => getImage(wpToken?.featuredImage?.node as ImageDataLike),
    [wpToken?.featuredImage?.node]
  )
  const breadcrumbs = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: t("breadcumbs.markets_breadcrumb"),
        link: "/exchange/",
      },
      {
        text: wpToken.customFields.name,
        link: `/exchange/${wpToken.customFields.key.toLowerCase()}/`,
      },
    ],
    [t, wpToken.customFields.name, wpToken.customFields.key]
  )

  const url = React.useMemo(() => {
    return (
      wpToken.featuredImage?.node?.mediaItemUrl ||
      "https://husmen.xyz/portfolio/kafka-stream/featured.png"
    )
  }, [wpToken.featuredImage?.node?.mediaItemUrl])

  const altText = React.useMemo(
    () => wpToken.featuredImage?.node?.altText || "Immagine Articolo Default",
    [wpToken.featuredImage?.node?.altText]
  )

  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink={HEADER_TOKEN_PAGES_DYNAMIC_LINK}
        appleLink={"https://youngexchange.page.link/stpgfios"}
        playStoreLink={"https://youngexchange.page.link/stpgfa"}
      >
        <MetaDecorator
          metaTitle={wpToken?.seo?.title}
          metaDescription={wpToken?.seo?.metaDesc}
          image={url}
        />
        <WebPageSchema
          title={wpToken?.seo?.title}
          description={wpToken?.seo?.metaDesc}
          breadcrumbs={breadcrumbs}
        />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadcrumbs} />
          <Box mt={["30px", "54px"]}>
            <ResponsiveText
              as="h1"
              dimension={["34px", "56px"]}
              line={["39px", "60px"]}
              fontWeight={600}
            >
              {wpToken.title}
            </ResponsiveText>
            <Box maxWidth="755px" mt={["16px", "24px"]}>
              <ResponsiveText
                as="p"
                dimension={["16px", "20px"]}
                line={["24px", "30px"]}
              >
                {wpToken?.seo?.metaDesc}
              </ResponsiveText>
            </Box>
            <Box mt={["16px", "24px"]}>
              <StyledStack>
                <SeoLink
                  isExternal
                  isConversion
                  link={BODY_TOKEN_PAGES_DYNAMIC_LINK}
                  rel="noopener"
                >
                  <ResponsiveButton size="lg" isRounded>
                    {t("single_market.pro_cta")}
                  </ResponsiveButton>
                </SeoLink>
                {!isTokenPro && isTokenUnlisted ? (
                  <HStack spacing="8px" align={["flex-start", "center"]}>
                    <Icon fontSize={"16px"} icon="info" color="grey.1" />
                    <Box as="span" ml="8px">
                      <HStack justify="start" align="start">
                        <ResponsiveText
                          color="grey.1"
                          as="p"
                          dimension={["14px", "16px"]}
                          line={["18px", "20px"]}
                          style={{
                            textDecoration: "underline",
                            textDecorationColor: "transparent",
                          }}
                        >
                          {wpToken?.customFields?.name}
                          &ensp;
                          <Box
                            onClick={handleNavigate}
                            as="span"
                            style={{
                              textDecoration: "underline",
                              textDecorationColor: "var(--r-grey-1)",
                              color: "var(--r-grey-1)",
                              fontFamily: "Matter",
                            }}
                          >
                            {t("single_market.disclaimers.top")}
                          </Box>
                        </ResponsiveText>
                      </HStack>
                    </Box>
                  </HStack>
                ) : null}
              </StyledStack>
            </Box>
          </Box>
        </Container>
        <Container size={["md", "lg"]}>
          <FlexWrapper>
            <Box className="content-box">
              {image ? (
                <Box
                  mt={["48px", "50px"]}
                  overflow="hidden"
                  border="1px solid"
                  borderColor="grey.5"
                  borderRadius="16px"
                  width="100%"
                  mb="48px"
                  style={{
                    transform: "translateZ(0)",
                  }}
                >
                  <GatsbyImage image={image} alt={altText} />
                </Box>
              ) : null}

              <TokenContentContainer content={wpToken.content} />
              <Box mt="48px">
                <Heading
                  as="h2"
                  fontFamily="Matter"
                  size="xxxl"
                  fontWeight={600}
                >
                  {t("single_market.price_section.title", {
                    currency: wpToken.customFields.name,
                  })}
                </Heading>
                <Box mt={["16px", "24px"]}>
                  <PriceSection
                    isLoading={isLoading}
                    current_price={String(data?.price)}
                    high={""}
                    percent_change_24h={
                      String(data?.details?.priceChange24h) || "0"
                    }
                    max_supply={String(data?.details?.maxSupply)}
                    current_supply={String(data?.details?.circulatingSupply)}
                    market_cap={String(data?.details?.marketCap)}
                    volume_24h={String(data?.details?.volume24h)}
                    symbol={tokenSymbol.toUpperCase()}
                  />
                </Box>
              </Box>
              {wpToken?.customFields?.academyid ||
                wpToken?.customFields?.blogRelatedArticle ? (
                <Box mt="48px">
                  <Heading
                    as="h2"
                    fontFamily="Matter"
                    size="xxxl"
                    fontWeight={600}
                  >
                    {t("single_market.relatedArticle.title")}
                  </Heading>
                  <Box mt={["16px", "24px"]}>
                    <ResponsiveText
                      as="p"
                      dimension={["16px", "20px"]}
                      line={["24px", "30px"]}
                    >
                      {t("single_market.relatedArticle.description")}
                    </ResponsiveText>
                  </Box>
                  {wpToken?.customFields?.academyid ? (
                    <Box mt={["16px", "24px"]}>
                      <AcademyRelatedArticle
                        id={wpToken?.customFields?.academyid}
                      />
                    </Box>
                  ) : null}
                  {wpToken?.customFields?.blogRelatedArticle ? (
                    <Box mt={["16px", "24px"]}>
                      <BlogRelatedArticle
                        {...wpToken?.customFields?.blogRelatedArticle}
                      />
                    </Box>
                  ) : null}
                </Box>
              ) : null}
              <Box mt="48px">
                <Heading
                  as="h2"
                  fontFamily="Matter"
                  size="xxxl"
                  fontWeight={600}
                >
                  {t(
                    `single_market.actionSection.title.${isTokenUnlisted ? "unlisted" : "listed"
                    }`,
                    { currency: wpToken.customFields.name }
                  )}
                </Heading>
                <Box mt={["16px", "24px"]}>
                  <ActionSection type="unlisted" />
                </Box>
                <Box mt={["16px", "24px"]}>
                  <SeoLink
                    isExternal={true}
                    isConversion
                    link={BODY_TOKEN_PAGES_DYNAMIC_LINK}
                    rel="noopener"
                  >
                    <BtnContainer>
                      <ResponsiveButton className="xs-fullwidth">
                        {t(
                          isTokenPro
                            ? "single_market.buy_block.btn"
                            : "single_market.actionSection.cta"
                        )}
                      </ResponsiveButton>
                    </BtnContainer>
                  </SeoLink>
                </Box>
              </Box>
              <Box mt="48px">
                <Heading
                  as="h2"
                  fontFamily="Matter"
                  size="xxxl"
                  fontWeight={600}
                >
                  {t("single_market.brand.title")}
                </Heading>

                <Box mt={["16px", "24px"]}>
                  <ResponsiveText
                    as="p"
                    dimension={["16px", "20px"]}
                    line={["24px", "30px"]}
                  >
                    {t("single_market.brand.description")}
                  </ResponsiveText>
                </Box>
              </Box>

              <Box mt="48px">
                <Heading
                  as="h2"
                  fontFamily="Matter"
                  size="xxxl"
                  fontWeight={600}
                >
                  {t("single_market.relatedCoins.title")}
                </Heading>

                <Box mt={["16px", "24px"]}>
                  <ResponsiveText
                    as="p"
                    dimension={["16px", "20px"]}
                    line={["24px", "30px"]}
                  >
                    {t("single_market.relatedCoins.description")}
                  </ResponsiveText>
                </Box>
                <Box mt={["16px", "24px"]}>
                  {
                    <ReletedLabelTokens
                      type="top_gainers"
                      exclude={wpToken.customFields.key}
                    />
                  }
                </Box>
              </Box>
              <Box mt="48px">
                <LegalFooter />
              </Box>
            </Box>
            <Box
              position="relative"
              className="front-matter"
              mt={["48px", "50px"]}
              width="100%"
            >
              {frontMatter ? (
                <FrontMatter frontMatter={frontMatter} />
              ) : (
                <Skeleton
                  borderRadius="16px"
                  maxWidth="368px"
                  width="100%"
                  height="396px"
                />
              )}
            </Box>
          </FlexWrapper>
        </Container>
        <BottomBanner
          text={t(
            isTokenPro
              ? "single_market.buy_block.title"
              : "single_market.footer.title",
            { currency: wpToken.customFields.name }
          )}
          mt={["60px", "96px"]}
        >
          <SeoLink
            isExternal={true}
            isConversion
            link={BODY_TOKEN_PAGES_DYNAMIC_LINK}
            rel="noopener"
          >
            <ResponsiveButton size="lg" variant="primary">
              {t(
                isTokenPro
                  ? "single_market.buy_block.btn"
                  : "single_market.footer.cta"
              )}
            </ResponsiveButton>
          </SeoLink>
        </BottomBanner>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query SingleUnlistedToken($language: String!, $databaseId: Int!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpToken(databaseId: { eq: $databaseId }) {
      title
      content
      featuredImage {
        node {
          mediaItemUrl
          altText
          gatsbyImage(width: 756)
        }
      }
      seo {
        metaDesc
        title
      }
      slug
      customFields {
        ispro
        isunlisted
        academyid
        ticker
        key
        name
        blogRelatedArticle {
          ... on WpPost {
            title
            slug
            categories {
              nodes {
                slug
              }
            }
            featuredImage {
              node {
                gatsbyImage(width: 756, height: 472)
              }
            }
            seo {
              title
              metaDesc
            }
          }
        }
      }
    }
  }
`

export default Unlisted
