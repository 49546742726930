import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { DistributionType } from "../types"

const useDistribution = (type: DistributionType) => {
  const { t } = useI18next()
  if (type === "total") {
    return React.useMemo(() => {
      return [
        {
          key: "all",
          name: t("token_yng.distribution.box_2.labels.title_1"),
          color: t("token_yng.distribution.box_2.labels.color_1"),
          value: 100000000,
        },
        {
          key: "circulating",
          name: t("token_yng.distribution.box_2.labels.title_2"),
          color: t("token_yng.distribution.box_2.labels.color_2"),
          value: 22690000,
        },
        {
          key: "team",
          name: t("token_yng.distribution.box_2.labels.title_4"),
          color: t("token_yng.distribution.box_2.labels.color_4"),
          value: 7000000,
        },
        {
          key: "liquidity-provider",
          name: t("token_yng.distribution.box_2.labels.title_3"),
          color: t("token_yng.distribution.box_2.labels.color_3"),
          value: 23000000,
        },
        {
          key: "community",
          name: t("token_yng.distribution.box_2.labels.title_6"),
          color: t("token_yng.distribution.box_2.labels.color_6"),
          value: 47310000,
        },
      ]
    }, [t])
  }
  return React.useMemo(() => {
    return [
      {
        key: "all",
        name: t("token_yng.distribution.box_1.labels.title_1"),
        color: t("token_yng.distribution.box_1.labels.color_1"),
        value: 22690000,
      },
      {
        key: "yp",
        name: t("token_yng.distribution.box_1.labels.title_2"),
        color: t("token_yng.distribution.box_1.labels.color_2"),
        value: 5630000,
      },
      {
        key: "clubs",
        name: t("token_yng.distribution.box_1.labels.title_3"),
        color: t("token_yng.distribution.box_1.labels.color_3"),
        value: 9910000,
      },
      {
        key: "pool",
        name: t("token_yng.distribution.box_1.labels.title_4"),
        color: t("token_yng.distribution.box_1.labels.color_4"),
        value: 4490000,
      },
      {
        key: "step",
        name: t("token_yng.distribution.box_1.labels.title_6"),
        color: t("token_yng.distribution.box_1.labels.color_6"),
        value: 2660000,
      },
    ]
  }, [t])
}

export default useDistribution
