export const b2bEvents = {
  b2b_home_hero_start: "b2b_home_hero_start" as const,
  b2b_home_body_overview: "b2b_home_body_overview" as const,
  b2b_home_body_edu_service: "b2b_home_body_edu_service" as const,
  b2b_home_body_trading_service: "b2b_home_body_trading_service" as const,
  b2b_home_footer: "b2b_home_footer" as const,
  b2b_home_body_treasury: "b2b_home_body_treasury" as const,
  b2b_home_body_payments: "b2b_home_body_payments" as const,
  b2b_home_body_courses: "b2b_home_body_courses" as const,
  b2b_home_body_history: "b2b_home_body_history" as const,
  b2b_home_body_advocacy: "b2b_home_body_advocacy" as const,
}

export type B2bHomeEvents = typeof b2bEvents
