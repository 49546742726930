import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
} from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { createArrayFromJson } from "../../../../utils/general"
import { Text } from "../../../../components/texts/temporary"
import { Container, ResponsiveText } from "../../../../components/layout"

export const FaqsSection = () => {
  const { t } = useTranslation()
  const faqs = React.useMemo(() => {
    return createArrayFromJson(
      t,
      "b2b.faqs_section.cards",
      5,
      [],
      "question",
      "answer"
    )
  }, [t])
  return (
    <Container size={["fullwidth", "lg"]}>
      <Text.H3 fontWeight={600} textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t("b2b.faqs_section.title"),
        }} />
      <Box mt={["40px", "48px"]}>
        <Accordion allowMultiple>
          {faqs.map((faq, index) => {
            return (
              <AccordionItem
                key={index}
                mt={index === 0 ? "0px" : "24px"}
                borderRadius="16px"
                overflow="hidden"
                background={"var(--grey-10-v2)"}
                py={["36px", "44px"]}
                px={["24px", "48px"]}
              >
                <AccordionButton
                  justifyContent="space-between"
                  padding="unset !important"
                >
                  <ResponsiveText
                    dimension={["18px", "24px"]}
                    line={["24px", "32px"]}
                    fontWeight={600}
                  >
                    {faq.question}
                  </ResponsiveText>
                  <AccordionIcon
                    icon="chevron_down"
                    fontSize={"24px"}
                  />
                </AccordionButton>
                <AccordionPanel mt="24px">
                  <ResponsiveText
                    dimension={["16px", "18px"]}
                    line={["24px", "24px"]}
                  >
                    {faq.answer}
                  </ResponsiveText>
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </Box>
    </Container>
  )
}
