import { Body, Container, VStack, Heading, Icon, Tag } from "@youngagency/ui"
import {
  Avatar,
  Container as CustomContainer,
} from "../../../../components/layout"
import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { SquareCard } from "../../../../components/cards/components/SquareCard"
import { CardContent } from "../../../../components/cards/layout/CardContent"
import { centeredSquareCard } from "../../../../components/cards/style/card-content.css"
import { CardContainer } from "../../../../components/cards/layout/CardContainer"
import {
  autoHeightCard,
  unshirkedCard,
} from "../../../../components/cards/style/card.css"
import { CardSlider } from "../../../../components/cards/components/CardSlider"
import clsx from "clsx"

export const CertificatesSection = () => {
  const { t } = useI18next()
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              __maxWidth: "1180px",
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("b2b.certificates_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("b2b.certificates_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>

      <CardSlider
        marginTop={{
          mobile: 24,
          md: 32,
        }}
      >
        <SquareCard disableHover>
          <CardContainer
            width={"full"}
            background={"grey10"}
            className={clsx(autoHeightCard, unshirkedCard)}
          >
            <CardContent
              width={"full"}
              className={centeredSquareCard}
            >
              <VStack
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
              >
                <Tag size={16} colorScheme="green" style={{ width: "fit-content", color: "green.300!important" }}>
                  {t("b2b.certificates_section.card_1.title")}
                </Tag>
                <VStack spacing={8}>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("b2b.certificates_section.card_1.label"),
                    }}
                  />
                  <Body
                    variant="1"
                    color="grey200"
                    fontWeight={600}
                  >
                    {t("b2b.certificates_section.card_1.description")}
                  </Body>
                </VStack>
              </VStack>
            </CardContent>
          </CardContainer>
        </SquareCard>
        <SquareCard disableHover>
          <CardContainer
            width={"full"}
            background={"grey10"}
            className={clsx(autoHeightCard, unshirkedCard)}
          >
            <CardContent
              width={"full"}
              className={centeredSquareCard}
            >
              <VStack
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
              >
                <Tag size={16} colorScheme="green" style={{ width: "fit-content", color: "green.300!important" }}>
                  {t("b2b.certificates_section.card_2.title")}
                </Tag>
                <VStack spacing={8}>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("b2b.certificates_section.card_2.label"),
                    }}
                  />
                  <Body
                    variant="1"
                    fontWeight={600}
                    color="grey200"
                  >
                    {t("b2b.certificates_section.card_2.description")}
                  </Body>
                </VStack>
              </VStack>
            </CardContent>
          </CardContainer>
        </SquareCard>
        <SquareCard disableHover>
          <CardContainer
            width={"full"}
            background={"grey10"}
            className={clsx(autoHeightCard, unshirkedCard)}
          >
            <CardContent
              width={"full"}
              className={centeredSquareCard}
            >
              <VStack
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
              >

                <Tag size={16} colorScheme="green" style={{ width: "fit-content", color: "green.300!important" }}>
                  {t("b2b.certificates_section.card_3.title")}
                </Tag>

                <VStack spacing={8}>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("b2b.certificates_section.card_3.label"),
                    }}
                  />
                  <Body
                    variant="1"
                    fontWeight={600}
                    color="grey200"
                  >
                    {t("b2b.certificates_section.card_3.description")}
                  </Body>
                </VStack>
              </VStack>
            </CardContent>
          </CardContainer>
        </SquareCard>
      </CardSlider>
    </>
  )
}
