import { Body, Container, VStack, Heading } from "@youngagency/ui"
import {
  Container as CustomContainer,
} from "../../../../components/layout"
import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { SquareCard } from "../../../../components/cards/components/SquareCard"
import { CardContainer } from "../../../../components/cards/layout/CardContainer"
import {
  autoHeightCard,
  unshirkedCard,
} from "../../../../components/cards/style/card.css"
import { CardSlider } from "../../../../components/cards/components/CardSlider"
import clsx from "clsx"
import { navigate } from "@reach/router"
import { b2bEvents } from "../../../../service/tracking/events/business"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { StaticImage } from "gatsby-plugin-image"

export const AdvocacySection = () => {
  const { t } = useI18next();
  const { gaTracker } = useSelector((state: RootState) => state.tracking);

  const handleClick = React.useCallback(
    (index: number) => {
      if (gaTracker)
        gaTracker.trackMultipleEvents([
          {
            eventName: b2bEvents.b2b_home_body_advocacy,
            payload: {
              action: index,
            },
          },
        ])
      switch (index) {
        case 1:
          navigate("https://www.youtube.com/watch?v=xmpqbb6aIyQ&list=PLDB4Psx2i6zzSLdaDtXWrFN8fZPTjByWO&index=1");
          break
        case 2:
          navigate("https://www.youtube.com/watch?v=ARDclcQ4GN8&list=PLDB4Psx2i6zzSLdaDtXWrFN8fZPTjByWO&index=2");
          break
        case 3:
          navigate("https://www.youtube.com/watch?v=ITPuuurY5YY&list=PLDB4Psx2i6zzSLdaDtXWrFN8fZPTjByWO&index=3");
          break
      }
    },
    [gaTracker]);

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              __maxWidth: "1180px",
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              color={"white"}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("b2b.advocacy_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("b2b.advocacy_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>

      <CardSlider
        marginTop={{
          mobile: 24,
          md: 32,
        }}
      >
        <SquareCard isAnimated>
          <CardContainer
            width={"full"}
            background={"grey700"}
            className={clsx(autoHeightCard, unshirkedCard)}
            onClick={() => handleClick(1)}
          >
            <StaticImage
              src="../../images/advocacy-1.png"
              alt={t("b2b.advocacy_section.card_1.img_alt_text")}
            />
            <VStack
              align="flex-start"
              style={{
                padding: "24px",
              }}
              spacing={{
                mobile: 8,
                lg: 8,
              }}
            >
              <Body
                variant="1"
                fontWeight={600}
                color={"white"}
                atoms={{
                  textAlign: "left",
                }}
              >
                {t("b2b.advocacy_section.card_1.title")}
              </Body>
              <Body
                variant="1"
                color="grey200"
                atoms={{
                  textAlign: "left",
                }}
                textTransform="uppercase"
              >
                {t("b2b.advocacy_section.card_1.description")}
              </Body>
            </VStack>
          </CardContainer>
        </SquareCard>
        <SquareCard isAnimated>
          <CardContainer
            width={"full"}
            background={"grey700"}
            className={clsx(autoHeightCard, unshirkedCard)}
            onClick={() => handleClick(2)}
          >
            <StaticImage
              src="../../images/advocacy-2.png"
              alt={t("b2b.advocacy_section.card_2.img_alt_text")}
            />
            <VStack
              align="flex-start"
              style={{
                padding: "24px",
              }}
              spacing={{
                mobile: 8,
                lg: 8,
              }}
            >
              <Body
                variant="1"
                fontWeight={600}
                color={"white"}
                atoms={{
                  textAlign: "left",
                }}
              >
                {t("b2b.advocacy_section.card_2.title")}
              </Body>
              <Body
                variant="1"
                color="grey200"
                atoms={{
                  textAlign: "left",
                }}
                textTransform="uppercase"
              >
                {t("b2b.advocacy_section.card_2.description")}
              </Body>
            </VStack>
          </CardContainer>
        </SquareCard>
        <SquareCard isAnimated>
          <CardContainer
            width={"full"}
            background={"grey700"}
            className={clsx(autoHeightCard, unshirkedCard)}
            onClick={() => handleClick(3)}
          >
            <StaticImage
              src="../../images/advocacy-3.png"
              alt={t("b2b.advocacy_section.card_3.img_alt_text")}
            />
            <VStack
              align="flex-start"
              style={{
                padding: "24px",
              }}
              spacing={{
                mobile: 8,
                lg: 8,
              }}
            >
              <Body
                variant="1"
                fontWeight={600}
                color={"white"}
                atoms={{
                  textAlign: "left",
                }}
              >
                {t("b2b.advocacy_section.card_3.title")}
              </Body>
              <Body
                variant="1"
                color="grey200"
                atoms={{
                  textAlign: "left",
                }}
                textTransform="uppercase"
              >
                {t("b2b.advocacy_section.card_3.description")}
              </Body>
            </VStack>
          </CardContainer>
        </SquareCard>
      </CardSlider >
    </>
  )
}
