import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Box, Container, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardButton,
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import { Text } from "../../../../components/texts/temporary"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { textMargin } from "./b2b.css"
import { gridRight } from "../../../../components/cards/style/grid-layout.css"
import { Button } from "@youngagency/young-ui"
import { SeoLink } from "../../../seo/components/SeoLink"
export const TreasurySection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleBrochureTreasury = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bEvents.b2b_home_body_treasury,
        payload: {
          action: "brochure_treasury",
        },
      },
      {
        eventName: globalEvents.download_brochure,
      },
    ])
    navigate("https://storage.googleapis.com/young-documents/2025-B2B-Deck-Tesoreria.pdf")
  }, [gaTracker])


  const handleBrochureStaking = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bEvents.b2b_home_body_treasury,
        payload: {
          action: "brochure_staking",
        },
      },
      {
        eventName: globalEvents.download_brochure,
      },
    ])
    navigate("https://storage.googleapis.com/young-documents/2025-B2B-Deck-Staking.pdf")
  }, [gaTracker])

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={"unset"}
            atoms={{
              width: "full",
              mx: "auto",
              __alignItems: "center",
              __justifyContent: "center",
            }}
          >
            <Text.H3
              as="h2"
              textAlign="center"
              color="white"
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("b2b.treasury_section.title"),
              }}
            />
            <Box className={textMargin}>
              <Text.Body1
                textAlign="center"
                color="var(--grey-200-v2)"
                fontWeight={600}
                maxWidth="700px"
              >
                {t("b2b.treasury_section.description")}
              </Text.Body1>
            </Box>
          </VStack>
        </Container>
      </CustomContainer>

      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
            __columnGap: "1rem",
          }}
        >
          <CardGrid>
            <CardStack
              mt="1rem"
            >
              <Card
                isAnimated
                hasShadow
                background="#002A17"
                orientation="column"
                disableHover
                shape="square"
                minHeight="unset!important"
                height="unset!important"
                onClick={() => handleBrochureTreasury()}
              >
                <CardContent shape="square">
                  <Box
                    display="flex"
                    flexDirection={{
                      mobile: "column",
                      md: "row",
                    }}
                    justifyContent="space-between"
                    alignItems={{
                      mobile: "flex-start",
                      md: "center",
                    }}
                    width="full"
                  >
                    <CardTexts>
                      <Text.H6 as="h3" color="white" fontWeight={600}
                        dangerouslySetInnerHTML={{
                          __html: t("b2b.treasury_section.card_1.title"),
                        }} />
                      <Text.Body1 color="var(--text-light)" fontWeight={400}>
                        {t("b2b.treasury_section.card_1.description")}
                      </Text.Body1>
                    </CardTexts>
                    <Button size="md" colorScheme="white" mt={["24px", "0px"]} onClick={() => handleBrochure()}>
                      {t("b2b.treasury_section.card_1.cta")}
                    </Button>
                  </Box>
                </CardContent>
                <CardImage height="100%">
                  <StaticImage
                    style={{
                      height: "100%",
                    }}
                    src="../../images/banner-bg-green.png"
                    alt={t("b2b.treasury_section.card_1.img_alt_text")}
                  />
                </CardImage>
              </Card>
            </CardStack>
            <Box
              marginTop={{
                mobile: 16,
                md: 16,
              }}
            >
              <VStack
                spacing={"unset"}
                align="center"
                justify="center"
                atoms={{
                  __columnGap: "1rem",
                  width: "full",
                  mx: "auto",
                }}
              >
                <CardStack>
                  <Card
                    isAnimated
                    background="#002032"
                    hasShadow
                    orientation="column"
                    invertBtn
                    disableHover
                  >
                    <CardContent>
                      <CardTexts>
                        <Text.H6 as="h3" color="white" fontWeight={600}
                          dangerouslySetInnerHTML={{
                            __html: t("b2b.treasury_section.card_2.title"),
                          }} />
                        <Text.Body1 color="var(--text-light)" fontWeight={600}>
                          {t("b2b.treasury_section.card_2.description")}
                        </Text.Body1>
                      </CardTexts>
                    </CardContent>
                    <CardImage>
                      <StaticImage
                        src="../../images/treasury-card-2.png"
                        alt={t("b2b.treasury_section.card_2.img_alt_text")}
                      />
                    </CardImage>
                  </Card>
                  <Card
                    isAnimated
                    background="#131312"
                    hasShadow
                    orientation="column"
                    invertBtn
                    disableHover
                  >
                    <CardContent>
                      <CardTexts>
                        <Text.H6 as="h3" color="white" fontWeight={600}
                          dangerouslySetInnerHTML={{
                            __html: t("b2b.treasury_section.card_3.title"),
                          }} />
                        <Text.Body1 color="var(--grey-200-v2)" fontWeight={600}>
                          {t("b2b.treasury_section.card_3.description")}
                        </Text.Body1>
                      </CardTexts>
                    </CardContent>
                    <CardImage>
                      <StaticImage
                        src="../../images/treasury-card-3.png"
                        alt={t("b2b.treasury_section.card_3.img_alt_text")}
                      />
                    </CardImage>
                  </Card>
                </CardStack>
                <CardStack mt="1rem">
                  <div className={gridRight}>
                    <Card
                      isAnimated
                      hasShadow
                      background="#131312"
                      orientation="column"
                      onClick={() => {
                        handleBrochureStaking()
                      }}
                      invertBtn
                    >
                      <CardContent>
                        <CardTexts>
                          <Text.H6 as="h3" color="white" fontWeight={600}
                            dangerouslySetInnerHTML={{
                              __html: t("b2b.treasury_section.card_4.title"),
                            }} />
                          <Text.Body1 color="var(--grey-200-v2)" fontWeight={600}>
                            {t("b2b.treasury_section.card_4.description")}
                          </Text.Body1>
                        </CardTexts>
                        <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                          {t("b2b.treasury_section.card_4.button")}
                        </CardButton>
                      </CardContent>
                      <CardImage>
                        <StaticImage
                          src="../../images/treasury-card-4.png"
                          alt={t("b2b.treasury_section.card_4.img_alt_text")}
                        />
                      </CardImage>
                    </Card>

                    <SeoLink isExternal={false} link="/token-yng/clubs/">
                      <Card
                        isAnimated
                        hasShadow
                        background="#002A17"
                        orientation="column"
                        invertBtn
                      >
                        <CardContent>
                          <CardTexts>
                            <Text.H6 as="h3" color="white" fontWeight={600}>
                              {t("b2b.treasury_section.card_5.title")}
                            </Text.H6>
                            <Text.H6 color="green.300" fontWeight={600}>
                              {t("b2b.treasury_section.card_5.description")}
                            </Text.H6>
                          </CardTexts>
                          <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                            {t("b2b.treasury_section.card_5.button")}
                          </CardButton>
                        </CardContent>
                        <CardImage>
                          <StaticImage
                            src="../../images/treasury-card-5.png"
                            alt={t("b2b.treasury_section.card_5.img_alt_text")}
                          />
                        </CardImage>
                      </Card>
                    </SeoLink>
                  </div>
                </CardStack>
              </VStack>
            </Box>
          </CardGrid>
        </VStack>
      </Box >
    </>
  )
}
