import React from "react"
import { useCardContext } from "../context/CardContext"
import { Box } from "@youngagency/ui"
import {
  cardContainer,
  cardContainerVariant,
  cardShadow,
  darkCardHover,
  lightCardHover,
  shadowVariants,
} from "../style/card.css"
import clsx from "clsx"
import { DefaultCardProps } from "../types"
import { useCardAnimation } from "../hooks/useCardAnimation"
import { BoxExtendedProps } from "../../../types/system"
import { useOpacityContext } from "../context/OpacityContext"

type CardContainerProps = BoxExtendedProps<HTMLDivElement> & DefaultCardProps

export const CardContainer = ({
  className,
  children,
  style,
  onClick,
  ...rest
}: CardContainerProps) => {
  const { onChange } = useOpacityContext()
  const {
    shape,
    hasShadow,
    disableHover,
    hoverVariant,
    isAnimated,
    shadowVariant,
  } = useCardContext()
  const cardVariant = cardContainerVariant({ shape })
  const cardStyle = React.useMemo(() => {
    const cardStyle = [cardContainer, cardVariant]
    if (hasShadow) {
      cardStyle.push(shadowVariants({ weight: shadowVariant }))
    }
    if (disableHover) {
      return clsx(...cardStyle, className)
    }
    if (hoverVariant === "light") {
      cardStyle.push(lightCardHover)
    } else {
      cardStyle.push(darkCardHover)
    }
    return clsx(...cardStyle, className)
  }, [])

  const handleClick = React.useCallback((arg: any) => {
    if (!onChange) {
      if (onClick) {
        console.log("handleClick", onChange, onClick)
        onClick(arg)
      }
    }
    if (onChange) {
      onChange()
      if (onClick) {
        onClick(arg)
      }
    }
  }, [onChange])

  const { ref, cardsAnimatedStyle } = useCardAnimation(isAnimated)

  return (
    <Box
      {...rest}
      as="article"
      ref={ref}
      className={cardStyle}
      style={{ ...style, ...cardsAnimatedStyle }}
      onClick={onClick ? (arg) => handleClick(arg) : handleClick}
    >
      {children}
    </Box>
  )
}
