import { Box, css, styled, Button, Label } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { HeroStack, ResponsiveText } from "../../../../components/layout"
import { DistributionChart } from "../../components"
import useDistribution from "../../hooks/useDistribution"

const FlexWrapBox = styled(Box)(
  css({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& >*": {
      marginTop: "8px",
      "&:not(:last-of-type)": {
        marginRight: "8px",
      },
    },
  })
)

export const InitialSupply = () => {
  const { t } = useI18next()
  const data = useDistribution("circulating")
  const [active, setActive] = React.useState<
    "all" | string | number | string[] | number[]
  >("all")

  const handleHover = React.useCallback(
    (key: string) => {
      if (active === key) {
        return
      }
      setActive(key)
    },
    [active, setActive]
  )
  return (
    <Box>
      <HeroStack reverse="row">
        <Box maxWidth="465px">
          <ResponsiveText
            line={["20px", "16px"]}
            dimension={["16px", "12px"]}
            as="p"
          >
            {t("token_yng.distribution.box_1.caption")}
          </ResponsiveText>
          <Box mt={["16px", "24px"]}>
            <ResponsiveText
              line={["30px", "39px"]}
              dimension={["24px", "34px"]}
              as="h2"
              fontWeight={600}
            >
              {t("token_yng.distribution.box_1.title")}
            </ResponsiveText>
            <Box mt={["16px", "24px"]}>
              <ResponsiveText
                line={["20px", "20px"]}
                dimension={["16px", "16px"]}
                as="p"
              >
                {t("token_yng.distribution.box_1.description")}
              </ResponsiveText>
            </Box>
            <FlexWrapBox mt={["8px", "16px"]}>
              {data.map(label => {
                return (
                  <Button
                    key={label.value}
                    colorScheme="white"
                    style={{
                      borderColor: "var(--r-grey-5)",
                    }}
                    className={
                      label.key === active ? "active-distribution-label" : ""
                    }
                    size="sm"
                    onClick={() => handleHover(label.key)}
                    onFocus={() => handleHover(label.key)}
                    leftIcon={
                      label.key === "all" ? undefined : (
                        <Box
                          width={["8px"]}
                          height={["8px"]}
                          borderRadius="50%"
                          backgroundColor={label.color}
                        />
                      )
                    }
                  >
                    <Label
                      size="sm"
                      as="h3"
                      fontWeight={600}
                      fontFamily="Matter"
                    >
                      {label.name}
                    </Label>
                  </Button>
                )
              })}
            </FlexWrapBox>
          </Box>
        </Box>
        <DistributionChart active={active} data={data} />
      </HeroStack>
    </Box>
  )
}
