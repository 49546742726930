import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import { gridRight } from "./b2b.css"
import { Text } from "../../../../components/texts/temporary"

export const HistorySection = () => {
  const { t } = useTranslation()

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("b2b.history_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              style={{
                maxWidth: "700px",
              }}
              color="grey200"
              fontWeight={600}
            >
              {t("b2b.history_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
            __columnGap: "1rem",
          }}
        >
          <CardGrid>
            <div className={gridRight}>
              <Card
                isAnimated
                hasShadow
                background="#002032"
                orientation="column"
                disableHover
              >
                <CardContent>
                  <CardTexts>
                    <Body
                      variant="1"
                      atoms={{
                        __color: "var(--white)",
                      }}
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {t(
                        "b2b.history_section.card_1.label"
                      )}
                    </Body>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t("b2b.history_section.card_1.title"),
                      }} />
                    <Body
                      variant="1"
                      atoms={{
                        __color: "var(--text-light)",
                      }}
                      fontWeight={600}
                    >
                      {t(
                        "b2b.history_section.card_1.description"
                      )}
                    </Body>
                    <ul style={{ listStyleType: "circle", paddingLeft: "1rem" }}>
                      <Body
                        variant="1"
                        as="li"
                        atoms={{
                          __color: "var(--text-light)",
                        }}
                        fontWeight={600}
                      >
                        {t(
                          "b2b.history_section.card_1.li_1"
                        )}
                      </Body>
                      <Body
                        variant="1"
                        as="li"
                        atoms={{
                          __color: "var(--text-light)",
                        }}
                        fontWeight={600}
                      >
                        {t(
                          "b2b.history_section.card_1.li_2"
                        )}
                      </Body>
                      <Body
                        variant="1"
                        as="li"
                        atoms={{
                          __color: "var(--text-light)",
                        }}
                        fontWeight={600}
                      >
                        {t(
                          "b2b.history_section.card_1.li_3"
                        )}
                      </Body>
                    </ul>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/history-card-1.png"
                    alt={t(
                      "b2b.history_section.card_1.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
              >
                <CardContent>
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("b2b.history_section.card_2.title")}
                    </Heading>
                    <Text.H5 color="var(--green-300-v2)" fontWeight={600}>
                      {t(
                        "b2b.history_section.card_2.description"
                      )}
                    </Text.H5>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/history-card-2.png"
                    alt={t(
                      "b2b.history_section.card_3.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
            </div>
            <CardStack mt="1rem">
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("b2b.history_section.card_3.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600} style={{ maxWidth: "70%" }}>
                      {t(
                        "b2b.history_section.card_3.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/history-card-3.png"
                    alt={t(
                      "b2b.history_section.card_3.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                shape="square"
                disableHover
                invertBtn
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("b2b.history_section.card_4.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600} style={{ maxWidth: "80%" }}>
                      {t(
                        "b2b.history_section.card_4.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/history-card-4.png"
                    alt={t(
                      "b2b.history_section.card_4.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
            </CardStack>
          </CardGrid>
        </VStack>
      </Box>
    </>
  )
}
