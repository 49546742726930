import {
  Box,
  BoxProps,
  css,
  Heading,
  Icon,
  IconType,
  Paragraph,
  Skeleton,
} from "@youngagency/young-ui"
import { HStack } from "@youngagency/ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import styled from "styled-components"
import { getPercentageColor } from "../utils"
import { useMergeTokenChartData } from "../hooks/useMergeTokenChartData"
import { formatInternationalNumber } from "../../../utils/general"
import isEmpty from "lodash/isEmpty"

import { useTokenFiatContext } from "../token_fiat/context"

type Props = {
  current_price?: string
  percent_change_24h?: number | string
  high?: string
  current_supply?: string
  max_supply?: string
  market_cap?: string
  volume_24h?: string
  symbol?: string
  isLoading: boolean
}

const StyledBox = styled(Box)(
  css({
    borderTop: "1px solid var( --r-grey-5 )",
    borderBottom: "1px solid var( --r-grey-5 )",
    ".stack": {
      borderBottom: ["1px solid var( --r-grey-5 )"],
    },
  })
)

const StyledStack = styled(Box)(
  css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: ["column", "row"],
  })
)

const PriceContainer = styled(Box)(
  css({
    maxWidth: ["unset", "200px"],
    width: "100%",
    py: ["16px", "24px"],
    display: "flex",
    flexDirection: ["row", "column"],
    justifyContent: ["space-between", "flex-start"],
    alignItems: ["center", "flex-start"],
    "&:not(:last-of-type)": {
      borderBottom: ["1px solid var( --r-grey-5 )", "none"],
    },
  })
)

export const DataContainer = ({
  text,
  value,
  isLoading,
  icon,
  ...rest
}: {
  text: string
  value?: string | number
  isLoading?: boolean
  icon?: IconType
} & BoxProps) => {
  return (
    <PriceContainer {...rest}>
      <Heading
        size="sm"
        fontFamily="Matter"
        weight="heavy"
        color="#000"
        style={{
          textTransform: "uppercase",
        }}
      >
        {text}
      </Heading>
      {isLoading || !value ? (
        <Skeleton width="120px" height="16px" mt={["0px", "8px"]} />
      ) : (
        <HStack
          justify="flex-start"
          align="center"
          spacing="unset"
          atoms={{
            marginTop: {
              mobile: 0,
              lg: 8,
            },
            __columnGap: "2px",
          }}
          color="inherit"
        >
          {icon ? <Icon icon={icon} fontSize="16px" color="inherit" /> : null}
          <Paragraph color="inherit" size="md" fontFamily="Matter">
            {value}
          </Paragraph>
        </HStack>
      )}
    </PriceContainer>
  )
}

export const PriceSection = ({
  isLoading,
  current_supply,
  current_price,
  high,
  max_supply,
  volume_24h,
  market_cap,
  percent_change_24h,
  symbol,
}: Props) => {
  const { t, language } = useI18next()

  const formattedPercentageByLocale = React.useMemo(
    () => formatInternationalNumber(language, Number(percent_change_24h)),
    [language, percent_change_24h]
  )

  const formatBigNumber = React.useCallback(
    (value: number) => {
      if (value / 1000000000 > 1)
        return (value / 1000000000).toFixed(2) + " " + t("markets.b")

      if (value / 1000000 > 1)
        return (value / 1000000).toFixed(2) + " " + t("markets.m")

      if (value / 1000 > 1)
        return (value / 1000).toFixed(2) + " " + t("markets.k")
    },
    [t]
  )

  return (
    <StyledBox>
      <StyledStack className="stack">
        <DataContainer
          isLoading={isLoading}
          text={t("single_market.price_section.price")}
          value={current_price}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("single_market.price_section.variation")}
          value={`${formattedPercentageByLocale}%`}
          color={
            Number(percent_change_24h) >= 0
              ? "var(--green-200-v2)"
              : "var(--red-300-v2)"
          }
          icon={Number(percent_change_24h) >= 0 ? "arrow_up" : "arrow_down"}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("single_market.price_section.supply")}
          value={`${symbol} ${formatBigNumber(Number(current_supply) || 0)}
            `}
        />
      </StyledStack>
      <StyledStack>
        <DataContainer
          isLoading={isLoading}
          text={t("single_market.price_section.max_supply")}
          value={`${symbol} ${formatBigNumber(Number(max_supply) || 0)}
            `}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("single_market.price_section.market_cap")}
          value={`€ ${formatBigNumber(Number(market_cap) || 0)}
            `}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("single_market.price_section.volume")}
          value={`€ ${formatBigNumber(Number(volume_24h) || 0)}
            `}
        />
      </StyledStack>
    </StyledBox>
  )
}

export const YoungCustomPriceSection = () => {
  const { mergedData: token } = useMergeTokenChartData("YNG", "day")
  const { t, language } = useI18next()

  const isLoaded = React.useMemo(() => !!token, [token])

  const formattedPercentageByLocale = React.useMemo(
    () => formatInternationalNumber(language, Number(token?.percentageChange)),
    [language, token?.percentageChange]
  )

  const formatBigNumber = React.useCallback(
    (value: number) => {
      if (value / 1000000000 > 1)
        return (value / 1000000000).toFixed(2) + " " + t("markets.b")

      if (value / 1000000 > 1)
        return (value / 1000000).toFixed(2) + " " + t("markets.m")

      if (value / 1000 > 1)
        return (value / 1000).toFixed(2) + " " + t("markets.k")
    },
    [t]
  )

  const high = React.useMemo(() => {
    if (token?.points) {
      if (!isEmpty(token.points)) {
        const allTokenValue = token.points.map(point => {
          if (typeof point === "number") {
            return point
          }
          return point.value
        })
        return Math.max(...allTokenValue)
      }
    }
  }, [token])

  return (
    <StyledBox>
      <StyledStack className="stack">
        <DataContainer
          isLoading={!isLoaded}
          text={t("single_market.price_section.price")}
          value={`€ ${token?.price}`}
        />
        <DataContainer
          isLoading={!isLoaded}
          text={t("single_market.price_section.variation")}
          value={`${formattedPercentageByLocale}%`}
          color={getPercentageColor(token?.percentageChange)}
          icon={
            Number(token?.percentageChange) >= 0 ? "arrow_up" : "arrow_down"
          }
        />

        <DataContainer
          isLoading={!isLoaded}
          text={t("single_market.price_section.supply")}
          value={`YNG ${formatBigNumber(token?.details?.circulatingSupply || 0)}
            `}
        />
      </StyledStack>
      <StyledStack>
        <DataContainer
          isLoading={!isLoaded}
          text={t("single_market.price_section.high_value")}
          value={`YNG ${formatBigNumber(token?.details?.maxSupply || 0)}
            `}
        />
        <DataContainer
          isLoading={!isLoaded}
          text={t("single_market.price_section.market_cap")}
          value={`€ ${formatBigNumber(token?.details?.marketCap || 0)}`}
        />
        <DataContainer
          isLoading={!isLoaded}
          text={t("single_market.price_section.volume")}
          value={`€ ${formatBigNumber(token?.details?.volume24h || 0)}
            `}
        />
      </StyledStack>
    </StyledBox>
  )
}

type TokenFiatProps = {
  high_24h?: string | number
  high_7g?: string | number
  high?: string | number
  low_24h?: string | number
  low_7g?: string | number
  low?: string | number
  percent_change_24h?: string
  percent_change_7g?: string
  percent_change_2y?: string
  isLoading?: boolean
}

export const TokenFiatPriceSection = ({
  percent_change_2y,
  percent_change_24h,
  percent_change_7g,
  high_24h,
  high_7g,
  high,
  low,
  low_7g,
  low_24h,
  isLoading,
}: TokenFiatProps) => {
  const { t, language } = useI18next()

  const formatPercentage = React.useCallback(
    (number: string | number | undefined) =>
      formatInternationalNumber(language, Number(number)),
    [language]
  )

  const formattedPercentageValue = React.useMemo(
    () => ({
      week: formatPercentage(percent_change_7g),
      day: formatPercentage(percent_change_24h),
      all: formatPercentage(percent_change_2y),
    }),
    [formatPercentage, percent_change_24h, percent_change_7g, percent_change_2y]
  )

  return (
    <StyledBox>
      <StyledStack className="stack">
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.price_change.24h")}
          value={`${formattedPercentageValue.day}%`}
          color={
            Number(percent_change_24h) >= 0 ? "var(--green)" : "var(--red)"
          }
          icon={Number(percent_change_24h) >= 0 ? "arrow_up" : "arrow_down"}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.ATL.24h")}
          value={low_24h}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.ATH.24h")}
          value={high_24h}
        />
      </StyledStack>
      <StyledStack className="stack">
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.price_change.7g")}
          value={`${formattedPercentageValue.week}%`}
          color={Number(percent_change_7g) >= 0 ? "var(--green)" : "var(--red)"}
          icon={Number(percent_change_7g) >= 0 ? "arrow_up" : "arrow_down"}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.ATL.7g")}
          value={low_7g}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.ATH.7g")}
          value={high_7g}
        />
      </StyledStack>
      <StyledStack className="stack">
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.price_change.2years")}
          value={`${formattedPercentageValue.all}%`}
          color={Number(percent_change_2y) >= 0 ? "var(--green)" : "var(--red)"}
          icon={Number(percent_change_2y) >= 0 ? "arrow_up" : "arrow_down"}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.ATL.2years")}
          value={low}
        />
        <DataContainer
          isLoading={isLoading}
          text={t("markets.token_fiat.price_section.table.ATH.2years")}
          value={high}
        />
      </StyledStack>
    </StyledBox>
  )
}
