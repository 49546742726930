import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Button } from "@youngagency/ui"
import { Box as LegacyBox } from "@youngagency/young-ui"
import {
  AbsoluteCardImageBox,
  Card,
  CardContent,
  CardGrid,
  CardTexts,
} from "../../../../components/cards"
import { hero, heroBlackBtn } from "./b2b.css"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { navigate } from "@reach/router"
import { Text } from "../../../../components/texts/temporary"
import { StaticImage } from "gatsby-plugin-image"
import { heroBtnsStack } from "../business-user/style.css"
import { hnwiTypeform } from "../../constants"
export const Hero = () => {
  const { t } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const ref_desktop = React.useRef(null)
  const ref_mobile = React.useRef(null)

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      var aScript = document.createElement("script");
      aScript.type = "text/javascript";
      aScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      aScript.async = true;
      document.head.appendChild(aScript);
      aScript.onload = function () {
        (window as any).Trustpilot.loadFromElement(ref_desktop.current);
        (window as any).Trustpilot.loadFromElement(ref_mobile.current);
      };
    }
  }, [])

  // const { handlePersistUtmCallback } = useHandlePersistUtm()

  // const handleExchangeClick = React.useCallback(() => {
  //   gaTracker?.trackMultipleEvents([
  //     {
  //       eventName: b2bEvents.b2b_home_hero_start,
  //       payload: {
  //         action: 1,
  //       },
  //     },
  //     {
  //       eventName: globalEvents.click_to_exchange,
  //     },
  //   ])
  //   handlePersistUtmCallback({
  //     callback: () => navigate("https://youngexchange.page.link/B2Bb"),
  //   })
  // }, [gaTracker, handlePersistUtmCallback])
  return (
    <CardGrid size="hero">
      <Card
        className={hero}
        disableHover
        backgroundColor="var(--grey-700-v2)"
        height={["auto !important", "calc(100vh - 98px) !important"]}
        position="relative"
        borderRadius={["0px !important", "32px !important"]}
      >
        <CardContent
          maxWidth="100% !important"
          alignItems={["center", "center"] as any}
          mr="0px !important"
        >
          <CardTexts mx="auto">
            <Text.H2
              as="h1"
              textAlign="center"
              fontWeight={600}
              color={"white"}
              dangerouslySetInnerHTML={{
                __html: t("b2b.hero.title"),
              }}
            />
            <LegacyBox maxWidth="616px" mt="24px" mx="auto">
              <Text.Subtitle2
                as="p"
                textAlign="center"
                fontWeight={600}
                color={"var(--grey-200-v2)"}
              >
                {t("b2b.hero.description")}
              </Text.Subtitle2>
            </LegacyBox>
            <LegacyBox display={["block", "none"]} mt="16px">
              <div ref={ref_mobile} className="trustpilot-widget" data-locale="it-IT" data-template-id="5419b637fa0340045cd0c936" data-businessunit-id="5fabb308d7384a0001bfb925" data-style-height="20px" data-style-width="100%" data-theme="dark">
                <a href="https://it.trustpilot.com/review/youngplatform.com" target="_blank" rel="noopener"></a>
              </div>
            </LegacyBox>
          </CardTexts>
          <div className={heroBtnsStack}>
            {/* <Button
              variant="solid"
              size={48}
              colorScheme="green"
              onClick={handleExchangeClick}
            >
              {t("b2b.hero.cta_join")}
            </Button> */}
            <Button
              variant="solid"
              size={48}
              style={{
                paddingLeft: 32,
                paddingRight: 32,
                height: 64,
              }}
              colorScheme="green"
              onClick={() => {
                gaTracker?.trackMultipleEvents([
                  {
                    eventName: b2bEvents.b2b_home_hero_start,
                    payload: {
                      action: "contacts",
                    },
                  },
                  {
                    eventName: globalEvents.contact_sales,
                  },
                ])
                navigate(hnwiTypeform)
              }}
            >
              {t("b2b.hero.cta_contact")}
            </Button>
          </div>
        </CardContent>
        <AbsoluteCardImageBox
          display={["none !important", "flex !important"]}
          height="100%"
        >
          <StaticImage
            quality={100}
            outputPixelDensities={[1, 2]}
            style={{
              height: "100%",
            }}
            placeholder="none"
            src="../../images/b2b_home_hero_2.png"
            alt={t("business_user.hero.alt_text_image")}
          />
        </AbsoluteCardImageBox>
        <LegacyBox style={{
          position: "absolute",
          bottom: "24px",
          // left: "10vw",
          left: "max(24px, calc(calc(100vw - 1180px) / 2))",
          zIndex: 1
        }}
          display={["none", "block"]}
          mt="24px"
          minHeight="150px"
        >
          <div ref={ref_desktop} className="trustpilot-widget" data-locale="it-IT" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5fabb308d7384a0001bfb925" data-style-width="190px" data-theme="dark" data-text-color="#ffffff">
            <a href="https://it.trustpilot.com/review/youngplatform.com" target="_blank" rel="noopener"></a>
          </div>
        </LegacyBox>
      </Card>
    </CardGrid >
  )
}
