import React from "react"
import { useRef } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Box, Container, Heading, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardGrid,
  CardContent as CardContentLegacy,
  CardStack,
} from "../../../../components/cards"
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion"
import { wrap } from "@motionone/utils"
import { parallax, scroller, scrollerItem } from "./b2b.css"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"

interface ParallaxProps {
  children: React.ReactNode
  baseVelocity: number
}

const ImageSection_1 = () => {
  const { t } = useTranslation()
  return (
    <div className={scroller}>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/emergency-white.png"
          // TO DO alt texts
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.emercency")}
        />
      </div>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/seedrs-white.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.seeders")}
        />
      </div>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          style={{
            filter: "invert(1)",
          }}
          src="../../images/logos/sella.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.sella")}
        />
      </div>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/azimut-white.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.azimut")}
        />
      </div>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          style={{
            filter: "invert(1)",
          }}
          src="../../images/logos/ithaca.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.ithaca")}
        />
      </div>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          style={{
            filter: "invert(1)",
          }}
          src="../../images/logos/digitech.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.digitech")}
        />
      </div>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/1caffe-white.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.1caffe")}
        />
      </div>
      <div
        className={scrollerItem}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/stc-white.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.save_the_children")}
        />
      </div>
    </div>
  )
}

type Props = React.HTMLAttributes<HTMLDivElement> & ParallaxProps

function ParallaxBox({ children, baseVelocity = 100, ...rest }: Props) {
  const baseX = useMotionValue(0)
  const { scrollY } = useScroll()
  const scrollVelocity = useVelocity(scrollY)
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  })
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  })

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
  const x = useTransform(baseX, v => `${wrap(-20, -45, v)}%`)

  const directionFactor = useRef<number>(1)
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000)

    /**
     * This is what changes the direction of the scroll once we
     * switch scrolling directions.
     */
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1
    }

    moveBy += directionFactor.current * (moveBy / 4) * velocityFactor.get()

    baseX.set(baseX.get() + moveBy)
  })

  /**
   * The number of times to repeat the child text should be dynamically calculated
   * based on the size of the text and viewport. Likewise, the x motion value is
   * currently wrapped between -20 and -45% - this 25% is derived from the fact
   * we have four children (100% / 4). This would also want deriving from the
   * dynamically generated number of children.
   */
  return (
    <div className={parallax} {...rest}>
      <motion.div className={scroller} style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  )
}

type ParallaxElementVelocity = {
  1: number
}

export const TrustedBySection = () => {
  const { t } = useTranslation()

  const [velocity, setVelocity] = React.useState<ParallaxElementVelocity>({
    1: 2,
  })

  const stopVelocity = React.useCallback((item: 1 | 2) => {
    return setVelocity({ ...velocity, [item]: 0 })
  }, [])

  const playVelocity = React.useCallback((item: 1 | 2) => {
    return setVelocity({ ...velocity, [item]: 2 })
  }, [])

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("b2b.trusted_section.title"),
              }}
            />
          </VStack>
        </Container>
      </CustomContainer>


      <Box
        marginTop={{
          mobile: 24,
          md: 32,
        }}>
        <ParallaxBox
          baseVelocity={-velocity[1]}
          onMouseEnter={() => stopVelocity(1)}
          onMouseLeave={() => playVelocity(1)}
        >
          <ImageSection_1 />
        </ParallaxBox>

      </Box>

      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            __columnGap: "1rem",
            width: "full",
            mx: "auto",
          }}
        >
          <CardGrid>
            <CardStack >
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
                alignItems={"center"}
              >
                <CardContentLegacy shape="square">
                  <VStack spacing={2} style={{ width: "100%" }} justify={"center"} align={"center"}>
                    <Heading as="h4" variant="6" color="white" fontWeight={600} style={{ textAlign: "center", marginBottom: "18px" }}>
                      {t("b2b.trusted_section.card_1.label")}
                    </Heading>
                    <Heading as="h3" variant="1" color="green300" fontWeight={600} style={{ textAlign: "center" }}>
                      {t("b2b.trusted_section.card_1.title")}
                    </Heading>
                    <Heading as="h4" variant="6" color="grey200" style={{ textAlign: "center" }}>
                      {t("b2b.trusted_section.card_1.description")}
                    </Heading>
                  </VStack>
                </CardContentLegacy>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
                alignItems={"center"}
              >
                <CardContentLegacy shape="square">
                  <VStack spacing={2} style={{ width: "100%" }} justify={"center"} align={"center"}>
                    <Heading as="h4" variant="6" color="white" fontWeight={600} style={{ textAlign: "center", marginBottom: "18px" }}>
                      {t("b2b.trusted_section.card_2.label")}
                    </Heading>
                    <Heading as="h3" variant="1" color="green300" fontWeight={600} style={{ textAlign: "center" }}>
                      {t("b2b.trusted_section.card_2.title")}
                    </Heading>
                    <Heading as="h4" variant="6" color="grey200" style={{ textAlign: "center" }}>
                      {t("b2b.trusted_section.card_2.description")}
                    </Heading>
                  </VStack>
                </CardContentLegacy>
              </Card>
            </CardStack>
          </CardGrid>
        </VStack>
      </Box>
    </>
  )
}
