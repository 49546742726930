import { Box, HStack, Icon, Button } from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Text } from "../../components/texts/temporary"
import { Card, CardContent, CardImage, CardTexts } from "../../components/cards"
import { HeroImage } from "./LocalizedImaged"
import { navigate } from "@reach/router"
import { useHandlePersistUtm } from "../navigation/hooks/useHandlePersistUtm"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { globalEvents, homePageEvents } from "../../service/tracking"

export const Hero = () => {
  const { t } = useTranslation()
  // const [loaded, setLoaded] = React.useState(false)
  const ref_desktop = React.useRef(null)
  const ref_mobile = React.useRef(null)
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      var aScript = document.createElement("script");
      aScript.type = "text/javascript";
      aScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      aScript.async = true;
      document.head.appendChild(aScript);
      aScript.onload = function () {
        (window as any).Trustpilot.loadFromElement(ref_desktop.current);
        (window as any).Trustpilot.loadFromElement(ref_mobile.current);
      };
    }
  }, [])

  const { handlePersistUtmCallback } = useHandlePersistUtm()
  return (
    <Card
      disableHover
      backgroundColor="var(--grey-10-v2)"
      height={["auto !important", "calc(100vh - 98px) !important"]}
      position="relative"
      borderRadius={["0px !important", "32px !important"]}
    >
      <CardContent
        pl={["24px", "96px!important"]}
        pr={["24px", "0px !important"]}
      >
        <CardTexts>
          <Text.H2 as="h1" fontWeight={600}>
            {t("homepage.hero.title")}
          </Text.H2>
          <Box maxWidth="616px" width="100%">
            <Text.Subtitle2
              color="var(--grey-200-v2)"
              fontWeight={600}
              as="div"
            >
              {t("homepage.hero.description")}
            </Text.Subtitle2>
          </Box>
        </CardTexts>
        <HStack mt="24px" spacing="16px" display={["none", "flex"]}>
          <Button
            size="xl"
            onClick={() => {
              if (gaTracker)
                gaTracker.trackMultipleEvents([
                  {
                    eventName: homePageEvents.home_hero_start,
                    payload: {
                      content: "sign_up",
                    },
                  },
                  {
                    eventName: globalEvents.click_to_exchange,
                  },
                ])
              handlePersistUtmCallback({
                callback: () => navigate("https://youngexchange.page.link/hDh"),
              })
            }}
          >
            {t("homepage.hero.button")}
          </Button>
          <Button
            onClick={() => {
              navigate("https://youngexchange.page.link/sBwZ")
            }}
            variant="secondary"
            colorScheme="white"
            size="xl"
            p="16px"
            style={{
              borderRadius: "50%",
            }}
          >
            <Icon icon="apple" />
          </Button>
          <Button
            onClick={() => {
              ; () => navigate("https://youngexchange.page.link/dr8x")
            }}
            variant="secondary"
            colorScheme="white"
            size="xl"
            p="16px"
            style={{
              borderRadius: "50%",
            }}
          >
            <Icon icon="google" />
          </Button>
        </HStack>
        <Button
          mt="16px"
          size="xl"
          isRounded
          display={["flex", "none"]}
          onClick={() => {
            if (gaTracker)
              gaTracker.trackMultipleEvents([
                {
                  eventName: homePageEvents.home_hero_start,
                  payload: {
                    content: "download",
                  },
                },
                {
                  eventName: globalEvents.click_to_exchange,
                },
              ])
            handlePersistUtmCallback({
              callback: () => navigate("https://youngexchange.page.link/hMh"),
            })
          }}
        >
          {t("homepage.hero.button")}
        </Button>
        <Box display={["none", "block"]} mt="24px" minHeight="150px">
          <div ref={ref_desktop} className="trustpilot-widget" data-locale="it-IT" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5fabb308d7384a0001bfb925" data-style-width="190px" data-theme="light" data-text-color="#000000">
            <a href="https://it.trustpilot.com/review/youngplatform.com" target="_blank" rel="noopener"></a>
          </div>
        </Box>
        <Box display={["block", "none"]} ml="-30px" mt="16px">
          <div ref={ref_mobile} className="trustpilot-widget" data-locale="it-IT" data-template-id="5419b637fa0340045cd0c936" data-businessunit-id="5fabb308d7384a0001bfb925" data-style-height="20px" data-style-width="100%" data-theme="light">
            <a href="https://it.trustpilot.com/review/youngplatform.com" target="_blank" rel="noopener"></a>
          </div>
        </Box>
      </CardContent>
      <CardImage
        position={["static", "absolute!important"]}
        top={["0px !important"]}
        left="0px !important"
        height={["auto", "100% !important"]}
        bottom="0px !important"
        width="100% !important"
        maxWidth="100% !important"
        p="0px !important"
      >
        <HeroImage />
      </CardImage>
    </Card>
  )
}
