import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, Icon, VStack } from "@youngagency/ui"
import { Avatar, Container as CustomContainer } from "../../../../components/layout"
import {
  CardButton,
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import { Text } from "../../../../components/texts/temporary"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { textMargin } from "./b2b.css"
import { gridRight } from "../../../../components/cards/style/grid-layout.css"
import { Button } from "@youngagency/young-ui"

export const CoursesSection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleAcademy = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bEvents.b2b_home_body_courses,
        payload: {
          action: "academy",
        },
      },
    ])
    navigate("https://academy.youngplatform.com/")
  },
    [gaTracker]
  )

  const handleBrochure = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bEvents.b2b_home_body_overview,
        payload: {
          action: 1,
        },
      },
      {
        eventName: globalEvents.download_brochure,
      },
    ])
    navigate("https://storage.googleapis.com/young-documents/2025-B2B-Training-Hub.pdf")
  }, [gaTracker])


  const handleYoutube = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bEvents.b2b_home_body_courses,
        payload: {
          action: "youtube",
        },
      },
    ])
    navigate("https://youtu.be/vZLQGxg_-AI")
  },
    [gaTracker]
  )

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={"unset"}
            atoms={{
              width: "full",
              mx: "auto",
              __alignItems: "center",
              __justifyContent: "center",
            }}
          >
            <Text.H3
              as="h2"
              textAlign="center"
              color="white"
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("b2b.courses_section.title"),
              }}
            />
            <Box className={textMargin}>
              <Text.Body1
                textAlign="center"
                color="var(--grey-200-v2)"
                fontWeight={600}
                maxWidth="700px"
              >
                {t("b2b.courses_section.description")}
              </Text.Body1>
            </Box>
          </VStack>
        </Container>
      </CustomContainer>


      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <CardGrid>
          <CardStack
            mt="1rem"
          >
            <Card
              isAnimated
              hasShadow
              background="#002A17;"
              orientation="column"
              disableHover
              shape="square"
              minHeight="unset!important"
              height="unset!important"
            >
              <CardContent shape="square">
                <Box
                  display="flex"
                  flexDirection={{
                    mobile: "column",
                    md: "row",
                  }}
                  justifyContent="space-between"
                  alignItems={{
                    mobile: "flex-start",
                    md: "center",
                  }}
                  width="full"
                >
                  <CardTexts>
                    <Text.H6 as="h3" color="white" fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t("b2b.courses_section.card_6.title"),
                      }} />
                    <Text.Body1 color="var(--text-light)" fontWeight={400}>
                      {t("b2b.courses_section.card_6.description")}
                    </Text.Body1>
                  </CardTexts>
                  <Button size="md" colorScheme="white" mt={["24px", "0px"]} onClick={() => handleBrochure()}>
                    {t("b2b.courses_section.card_6.cta")}
                  </Button>
                </Box>
              </CardContent>
              <CardImage height="100%">
                <StaticImage
                  style={{
                    height: "100%",
                  }}
                  src="../../images/banner-bg-black.png"
                  alt={t("b2b.treasury_section.card_1.img_alt_text")}
                />
              </CardImage>
            </Card>
          </CardStack>
        </CardGrid>
      </Box >


      <Box
        marginTop={{
          mobile: 16,
          md: 16,
        }}
      >
        <CardGrid>
          <CardStack>
            <div className={gridRight}>
              <Card
                isAnimated
                hasShadow
                background="#002A17"
                orientation="column_small"
                onClick={() => {
                  handleYoutube()
                }}
                minHeight={["330px!important", "initial"]}
              >
                <CardContent>
                  <CardTexts>
                    <Text.H6 as="h3" color="white" fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t("b2b.courses_section.card_1.title"),
                      }} />

                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/courses-card-1.png"
                    alt={t("b2b.courses_section.card_1.img_alt_text")}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#1C1233"
                orientation="column_small"
                onClick={() => {
                  handleAcademy()
                }}
                invertBtn
              >
                <CardContent>
                  <CardTexts>
                    <Text.H6 as="h3" color="white" fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t("b2b.courses_section.card_2.title"),
                      }} />
                    <Text.Body1 color="var(--text-light)" fontWeight={400}>
                      {t("b2b.courses_section.card_2.description")}
                    </Text.Body1>
                  </CardTexts>
                  <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                    {t("b2b.courses_section.card_2.button")}
                  </CardButton>
                </CardContent>
                <CardImage>
                  <StaticImage
                    style={{
                      marginBottom: "48px",
                    }}
                    src="../../images/courses-card-2.png"
                    alt={t("b2b.courses_section.card_2.img_alt_text")}
                  />
                </CardImage>
              </Card>
            </div>
          </CardStack>
        </CardGrid>
      </Box >


      <CardGrid>
        <CardStack
          mt="1rem"
        >
          <Card
            isAnimated
            hasShadow
            background="#131312"
            orientation="column"
            shape="square"
            invertBtn
            disableHover
          >
            <CardContent shape="square">
              <VStack
                align="center"
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
                style={{
                  width: "100%",
                }}
              >
                <Avatar
                  shape="circle"
                  width={["56px", "72px"]}
                  height={["56px", "72px"]}
                  background={"var(--grey-600-v2)"}
                >
                  <Icon
                    icon="safe"
                    color={"white"}
                    atoms={{
                      width: {
                        mobile: 24,
                        md: 32,
                      },
                      height: {
                        mobile: 24,
                        md: 32,
                      },
                    }}
                  />
                </Avatar>
                <Heading
                  variant="6"
                  atoms={{
                    textAlign: "center",
                  }}
                  color={"white"}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: t("b2b.courses_section.card_3.title"),
                  }}
                />
                <Body
                  variant="1"
                  color="grey200"
                  fontWeight={600}
                  atoms={{
                    textAlign: "center",
                  }}
                >
                  {t("b2b.courses_section.card_3.description")}
                </Body>
              </VStack>
            </CardContent>
          </Card>

          <Card
            isAnimated
            hasShadow
            background="#131312"
            orientation="column"
            shape="square"
            invertBtn
            disableHover
          >
            <CardContent shape="square">
              <VStack
                align="center"
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
                style={{
                  width: "100%",
                }}
              >
                <Avatar
                  shape="circle"
                  width={["56px", "72px"]}
                  height={["56px", "72px"]}
                  background={"var(--grey-600-v2)"}
                >
                  <Icon
                    icon="twoFactorAuthentication"
                    color={"white"}
                    atoms={{
                      width: {
                        mobile: 24,
                        md: 32,
                      },
                      height: {
                        mobile: 24,
                        md: 32,
                      },
                    }}
                  />
                </Avatar>
                <Heading
                  variant="6"
                  atoms={{
                    textAlign: "center",
                  }}
                  color={"white"}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: t("b2b.courses_section.card_4.title"),
                  }}
                />
                <Body
                  variant="1"
                  fontWeight={600}
                  color="grey200"
                  atoms={{
                    textAlign: "center",
                  }}
                >
                  {t("b2b.courses_section.card_4.description")}
                </Body>
              </VStack>
            </CardContent>
          </Card>

          <Card
            isAnimated
            hasShadow
            background="#131312"
            orientation="column"
            shape="square"
            invertBtn
            disableHover
          >
            <CardContent shape="square">
              <VStack
                align="center"
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
                style={{
                  width: "100%",
                }}
              >
                <Avatar
                  shape="circle"
                  width={["56px", "72px"]}
                  height={["56px", "72px"]}
                  background={"var(--grey-600-v2)"}
                >
                  <Icon
                    icon="medal"
                    color={"white"}
                    atoms={{
                      width: {
                        mobile: 24,
                        md: 32,
                      },
                      height: {
                        mobile: 24,
                        md: 32,
                      },
                    }}
                  />
                </Avatar>
                <Heading
                  variant="6"
                  atoms={{
                    textAlign: "center",
                  }}
                  color={"white"}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: t("b2b.courses_section.card_5.title"),
                  }}
                />
                <Body
                  variant="1"
                  fontWeight={600}
                  color="grey200"
                  atoms={{
                    textAlign: "center",
                  }}
                >
                  {t("b2b.courses_section.card_5.description")}
                </Body>
              </VStack>
            </CardContent>
          </Card>
        </CardStack>
      </CardGrid>

    </>
  )
}
